import type { PlaidAccount } from '@jcss/vue-plaid-link';
import { COUNTRY_CODE } from '../common/constants/constants';
import type { FundingSource, CheckfloProfile, FundingSourceListDto, CustomerStatus, AddressDto } from '../common/dtos';
import { AccountStatus } from '../common/enums/account-status';
import type { RegistrationFormValues, BankAdditionFormValues } from '../../base/components/PaperCheckFormModal.vue';
import type { CreatePlaidTokenRequest } from '../common/sign-up/bank/create-plaid-token-request';

export interface BankSource {
  id: FundingSource['id'];
  fundingSourceUrl: FundingSource['dwollaLocation'];
  bankName: FundingSource['bankName'];
  customName: FundingSource['name'];
  bankAccountType: FundingSource['bankAccountType'];
  status: FundingSource['status'];
  forPayments: FundingSource['forPayments'];
}

interface FundingSourcesState {
  banksTotalCount: number;
  bankSources: BankSource[];
  rtpBankSources: BankSource[];
  wallet: FundingSource;
  fundingFromSystem: boolean;
  checkfloProfile: CheckfloProfile;
  companyLogo: string | null;
  microDeposit: string;
  publicPlaidToken: string | null;
  bankForFees: BankSource | null;
  bankForPayments: BankSource | null;
  loading: boolean;
  isFundingSourcesLoaded: boolean;
  checkfloBankId: number | null;
}

const convertFundingToBankSource = (fs: FundingSource): BankSource => ({
  id: fs.id,
  fundingSourceUrl: fs.dwollaLocation,
  bankName: fs.bankName,
  customName: fs.name,
  bankAccountType: fs.bankAccountType,
  status: fs.status,
  forPayments: fs.forPayments,
});

export const useFundingSources = defineStore('fundingSources', {
  state: (): FundingSourcesState => ({
    banksTotalCount: 0,
    bankSources: [],
    wallet: {} as FundingSource,
    fundingFromSystem: true,
    checkfloProfile: {} as CheckfloProfile,
    companyLogo: null,
    microDeposit: '',
    publicPlaidToken: null,
    bankForFees: null,
    bankForPayments: null,
    loading: false,
    isFundingSourcesLoaded: false,
    checkfloBankId: null,
    rtpBankSources: [],
  }),
  actions: {
    async fetchAction(isSignUp = false): Promise<FundingSource[]> {
      const { $snapApi } = useNuxtApp();
      const url: string = isSignUp ? 'sign-up/banks' : 'transfer/get-funding-sources';

      const { data }: { data: FundingSourceListDto } = await $snapApi({
        method: 'GET',
        url,
      });

      const { totalCount, fundingSources } = data;

      this.banksTotalCount = totalCount;
      return fundingSources;
    },
    async fetchCheckfloProfileAction(isSignUp: boolean = false) {
      const { $snapApi } = useNuxtApp();

      const { data } = await $snapApi({
        method: 'get',
        url: isSignUp ? 'sign-up/checkflo/profile' : 'checkflo/getProfile',
      });

      this.checkfloProfile = data;
    },
    async fetchFundingSourcesWithCheckfloProfile() {
      this.loading = true;

      try {
        const fundingSources = await this.fetchAction();
        await this.fetchCheckfloProfileAction();
        this.setFundingSourcesData(fundingSources);
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async fetch(isSignUp = false) {
      this.loading = true;

      try {
        const fundingSources = await this.fetchAction(isSignUp);
        this.setFundingSourcesData(fundingSources);
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async fetchSystemFundingSource() {
      const { $snapApi } = useNuxtApp();
      const { data }: { data: FundingSourceListDto } = await $snapApi({
        method: 'get',
        url: 'transfer/get-funding-sources-system',
      });
      const { totalCount, fundingSources } = data;

      this.banksTotalCount = totalCount;

      this.setFundingSourcesData(fundingSources);
    },
    setFundingSourcesData(fundingSources: FundingSource[]) {
      this.bankForFees = null;
      this.bankSources = [];
      const balance = fundingSources.find((fs) => fs.type === 'balance');
      if (balance) {
        this.wallet = balance;
      }
      this.bankSources = fundingSources
        .filter((fs) => fs.type === 'bank')
        .map((fs) => {
          const bankSource = convertFundingToBankSource(fs);
          if (fs.forFees) {
            this.bankForFees = bankSource;
          }
          if (fs.forPayments) {
            this.bankForPayments = bankSource;
          }

          return bankSource;
        });
      this.checkfloBankId =
        fundingSources.find((fs) => this.checkfloProfile.banks && this.checkfloProfile.banks[fs.id])?.id ?? null;
      this.isFundingSourcesLoaded = true;
    },
    async setFundingSourceForFees(bankSource: BankSource) {
      const { $snapApi, $toastSuccess } = useNuxtApp();
      this.loading = true;
      await $snapApi({
        method: 'post',
        url: 'dwolla/funding-sources/fees',
        data: { fundingSourceUrl: bankSource.fundingSourceUrl },
      });
      this.bankForFees = bankSource;
      this.loading = false;
      $toastSuccess('Set bank for fees successfully');
    },
    async saveCheckfloProfile(profileId: number | undefined) {
      const { $snapApi } = useNuxtApp();

      await $snapApi({
        method: 'post',
        url: 'checkflo/saveProfile',
        data: {
          userid: profileId ?? this.checkfloProfile.profileId,
          data: this.checkfloProfile,
        },
      });
    },
    async fetchCheckfloProfile(isSignUp: boolean = false) {
      this.loading = true;

      try {
        await this.fetchCheckfloProfileAction(isSignUp);
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async addMerchant(registrationFormValues: RegistrationFormValues, profileId: number | undefined) {
      const { $snapApi } = useNuxtApp();

      registrationFormValues.federalTaxId = registrationFormValues.federalTaxId?.replace('-', '') ?? null;

      const data = {
        ...registrationFormValues,
        businessNumber: registrationFormValues.federalTaxId,
        user: profileId ?? this.checkfloProfile.profileId,
        countryCode: COUNTRY_CODE,
      };

      const response = await $snapApi({ method: 'post', url: 'checkflo/merchantAccount/RegisterAccount', data });

      this.checkfloProfile.merchantId = response.data.merchantAID;
    },
    async addBank(bankAdditionFormValues: BankAdditionFormValues, bank: BankSource) {
      const { $snapApi } = useNuxtApp();
      const { data: $auth } = useAuth();

      const data = {
        firstName: $auth.value?.firstName,
        lastName: $auth.value?.lastName,
        bankName: bank.bankName,
        bankNickname: bank.customName,
        accountType: bank.bankAccountType,
        inBehalf: this.checkfloProfile.merchantId,
        ...bankAdditionFormValues,
        printBankAddress: Number(bankAdditionFormValues.printBankAddress),
        printCompanyAddress: Number(bankAdditionFormValues.printCompanyAddress),
        printCompanyName: Number(bankAdditionFormValues.printCompanyName),
      };

      const bankId = bank.id.toString();

      const response = await $snapApi({ method: 'post', url: 'checkflo/merchantAccount/addBank', data });

      if (!this.checkfloProfile.banks || !this.checkfloProfile.bankStatus) {
        this.checkfloProfile.banks = { [bankId]: response.data.bankToken };
        this.checkfloProfile.bankStatus = { [bankId]: AccountStatus.Incomplete };
      } else {
        this.checkfloProfile.banks[bankId] = response.data.bankToken;
        this.checkfloProfile.bankStatus[bankId] = AccountStatus.Incomplete;
      }
    },
    async uploadLogo(bankId: string, image64: string, profileId: number | undefined) {
      const { $snapApi } = useNuxtApp();

      const bankToken = this.checkfloProfile.banks ? this.checkfloProfile.banks[bankId] : '';

      const data = {
        bankToken: bankToken,
        inBehalf: this.checkfloProfile.merchantId,
        logo: image64,
        userId: profileId ?? this.checkfloProfile.profileId,
      };

      await $snapApi({ method: 'post', url: 'checkflo/merchantAccount/uploadCompanyLogo', data });

      this.companyLogo = image64;
    },
    async saveCheckfloAddresses(addresses: AddressDto[]) {
      const { $snapApi } = useNuxtApp();

      await $snapApi({ method: 'post', url: 'checkflo/address', data: { addresses } });

      this.checkfloProfile.addresses = addresses;
    },
    async fetchMicroDeposit() {
      const { $snapApi } = useNuxtApp();
      const { data } = await $snapApi({ method: 'get', url: 'identity/get-micro-deposit' });

      this.microDeposit = data.data;
      this.banksTotalCount += data.data.length;
    },
    async fetchPlaidToken() {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const { data } = await $snapApi({ method: 'get', url: 'identity/link-token' });

        this.publicPlaidToken = data;
      } finally {
        this.loading = false;
      }
    },
    async createPlaidAccessToken(data: { public_token: string; accounts: PlaidAccount[] }) {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({ method: 'post', url: 'identity/token', data });
      } finally {
        this.loading = false;
      }
    },
    async saveMicroDepositBank(data: { accounts: PlaidAccount }, forFees: boolean) {
      const { $snapApi } = useNuxtApp();

      await $snapApi({ method: 'post', url: `identity/add-micro-deposit?for_fees=${forFees}`, data });
    },
    async createFundingSource(forFees: boolean) {
      const { $snapApi } = useNuxtApp();

      await $snapApi({ method: 'get', url: `identity/bank?for_fees=${forFees}` });

      this.fundingFromSystem = false;
    },
    async getCustomerStatus(): Promise<CustomerStatus['status']> {
      const { $snapApi } = useNuxtApp();
      const { data }: { data: CustomerStatus } = await $snapApi({ method: 'post', url: 'customer-status' });

      return data.status;
    },
    async removeFundingSource(fundingSourceUrl: string) {
      const { $snapApi, $toastSuccess, $toastError } = useNuxtApp();
      const data = {
        fundingSourceURL: fundingSourceUrl,
      };

      try {
        await $snapApi({
          method: 'POST',
          url: 'remove-funding-source',
          data,
        });

        this.fundingFromSystem = false;
        $toastSuccess('🗑️ Removed successfully');
        this.bankForFees = null;
        await this.fetch();
      } catch {
        $toastError('Invalid funding source');
      }
    },
    async verifyFundingSource(microDepositFundingUrl: string, firstAmount: string, secondAmount: string) {
      this.loading = true;
      const { $snapApi, $toastSuccess } = useNuxtApp();
      const data = {
        fundingSourceURL: microDepositFundingUrl,
        amount: Number(firstAmount),
        amount2: Number(secondAmount),
      };

      try {
        await $snapApi({
          method: 'POST',
          url: 'verify-micro-deposit',
          data,
        });

        await this.saveDefaultSource(microDepositFundingUrl);
        $toastSuccess("☑️ Nice! We've verified your bank account");
      } finally {
        this.loading = false;
      }
    },
    async saveDefaultSource(microDepositFundingUrl: string) {
      const { $snapApi } = useNuxtApp();
      const data = {
        fundingSourceURL: microDepositFundingUrl,
      };

      try {
        await $snapApi({
          method: 'POST',
          url: 'save-default-source',
          data,
        });

        this.fundingFromSystem = false;
        await this.fetch();
      } catch {}
    },
    async saveDefaultFundingSource(bankSource: BankSource) {
      const { $snapApi, $toastSuccess } = useNuxtApp();

      const data = {
        fundingSourceURL: bankSource.fundingSourceUrl,
      };

      this.loading = true;

      try {
        await $snapApi({ method: 'post', url: 'update-default-source', data });
        $toastSuccess('Set default bank successfully');
        this.bankForPayments = bankSource;
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async deleteFundingSource(sourceUrl: string) {
      const { $snapApi, $toastSuccess } = useNuxtApp();

      const data = {
        fundingSourceURL: sourceUrl,
      };

      this.loading = true;

      try {
        await $snapApi({ method: 'post', url: 'remove-funding-source', data });

        this.fundingFromSystem = false;

        $toastSuccess('🗑️ Removed successfully');

        await this.fetch();
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async fetchRtpFundingSources() {
      const { $snapApi } = useNuxtApp();
      this.loading = true;
      const { data }: { data: FundingSourceListDto } = await $snapApi({
        method: 'get',
        url: 'dwolla/funding-sources/rtp',
      });
      this.loading = false;
      const { totalCount, fundingSources } = data;

      this.banksTotalCount = totalCount;

      this.rtpBankSources = fundingSources
        .filter((fs) => fs.type === 'bank')
        .map((fs) => convertFundingToBankSource(fs));
    },
    async fetchLinkToken(): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        const response = await $snapApi({
          method: 'GET',
          url: 'sign-up/plaid/link-token',
        });

        this.publicPlaidToken = response.data;
      } finally {
        this.loading = false;
      }
    },
    async createToken(model: CreatePlaidTokenRequest): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({
          method: 'POST',
          url: 'sign-up/plaid/create-token',
          data: model,
        });
      } finally {
        this.loading = false;
      }
    },
    async createBankWithMicroDeposit(data: { accounts: PlaidAccount }, forFees: boolean): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({
          method: 'post',
          url: `sign-up/banks/with-micro-deposit?for_fees=${forFees}`,
          data,
        });

        this.fundingFromSystem = false;
      } finally {
        this.loading = false;
      }
    },
    async createBankWithoutMicroDeposit(forFees: boolean): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({
          method: 'post',
          url: `sign-up/banks/without-micro-deposit?for_fees=${forFees}`,
        });

        this.fundingFromSystem = false;
      } finally {
        this.loading = false;
      }
    },
    async fetchBanks() {
      const { $snapApi } = useNuxtApp();
      const url: string = 'sign-up/banks';

      this.loading = true;

      try {
        const { data }: { data: FundingSourceListDto } = await $snapApi({
          method: 'GET',
          url,
        });

        const { totalCount, fundingSources } = data;

        this.banksTotalCount = totalCount;

        this.setFundingSourcesData(fundingSources);
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async deleteBank(id: number): Promise<void> {
      const { $snapApi } = useNuxtApp();
      const url: string = `sign-up/banks/${id}`;

      this.loading = true;

      try {
        await $snapApi({
          method: 'POST',
          url,
        });
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async getTotalFeesAmount(accountId: number): Promise<number> {
      const { $snapApi } = useNuxtApp();
      const url: string = 'dwolla/funding-sources/total-fee-amount';

      const { data }: { data: number } = await $snapApi({
        method: 'GET',
        url,
        data: accountId,
      });

      return data;
    },
  },
});
